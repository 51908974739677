import { Grid } from "@mui/material";
import CardHeader from "@mui/material/CardHeader";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import React from "react";
import { useTranslation } from "react-i18next";
import Divider from "@mui/material/Divider";
import makeStyles from "@mui/styles/makeStyles";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Input from "@mui/material/Input";
import TextField from "@mui/material/TextField";

const useStyles = makeStyles((theme) => ({
  divider: {
    width: "100%",
    margin: "1rem 0",
  },
  title: {
    color: theme.palette.secondary.dark,
  },
}));

export default function PaidHours({
  timesheet,
  onUpdate,
  readOnly = true,
  hasCompensatoryDays = false,
  hasFamilyHours = false,
  familyHoursCustomName = "",
}) {
  const { t, i18n } = useTranslation("timesheets");
  const classes = useStyles();

  return (
    <Grid item sm={12} md={4}>
      <Card>
        <CardHeader
          title={t("paid_hours")}
          titleTypographyProps={{ variant: "h3" }}
          classes={{
            title: classes.title,
          }}
        />
        <CardContent>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              md={12}
              display={"flex"}
              justifyContent={"space-between"}
            >
              <Grid container spacing={2}>
                <Grid item xs={7}>
                  <Typography variant="h5">{t("sick")} :</Typography>
                </Grid>
                <Grid item xs={5}>
                  {readOnly ? (
                    <Typography>timesheet.paid_sick_hours</Typography>
                  ) : (
                    <Input
                      disableUnderline
                      onChange={onUpdate}
                      id={"paid_sick_hours"}
                      value={timesheet.paid_sick_hours}
                      type={"number"}
                    />
                  )}
                </Grid>
                <Grid item xs={7}>
                  <Typography variant="h5">{t("vacation")} :</Typography>
                </Grid>
                <Grid item xs={5}>
                  {readOnly ? (
                    <Typography>timesheet.paid_vacation_hours</Typography>
                  ) : (
                    <Input
                      disableUnderline
                      onChange={onUpdate}
                      id={"paid_vacation_hours"}
                      value={timesheet.paid_vacation_hours}
                      type={"number"}
                    />
                  )}
                </Grid>
                {hasFamilyHours && (
                  <>
                    <Grid item xs={7}>
                      <Typography variant="h5">
                        {familyHoursCustomName || t("family_hours")} :
                      </Typography>
                    </Grid>
                    <Grid item xs={5}>
                      {readOnly ? (
                        <Typography>timesheet.paid_family_hours</Typography>
                      ) : (
                        <Input
                          disableUnderline
                          onChange={onUpdate}
                          id={"paid_family_hours"}
                          value={timesheet.paid_family_hours}
                          type={"number"}
                        />
                      )}
                    </Grid>
                  </>
                )}
                <Grid item xs={7}>
                  {hasCompensatoryDays ? (
                    <Typography variant="h5">{t("compensatory")} :</Typography>
                  ) : (
                    <Typography variant="h5">{t("overtime")} :</Typography>
                  )}
                </Grid>
                <Grid item xs={5}>
                  {readOnly ? (
                    <Typography>timesheet.paid_overtime_hours</Typography>
                  ) : (
                    <Input
                      disableUnderline
                      onChange={onUpdate}
                      id={"paid_overtime_hours"}
                      value={timesheet.paid_overtime_hours}
                      type={"number"}
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
}
