import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import { useTranslation } from "react-i18next";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import makeStyles from "@mui/styles/makeStyles";
import { useDispatch, useSelector } from "react-redux";

import { displayAlert } from "../../slices/feedbackSlice";
import Intro from "./ConfigurationSteps/Intro";
import FiscalYearConfig from "./ConfigurationSteps/FiscalYearConfig";
import TimeOffConfig from "./ConfigurationSteps/TimeOffConfig";
import WorkingTimeConfig from "./ConfigurationSteps/WorkingTimeConfig";
import PublicHolidaysConfig from "./ConfigurationSteps/PublicHolidaysConfig";
import ConfigSummary from "./ConfigurationSteps/ConfigSummary";
import { createFiscalYear } from "../../slices/timesheetsSlice";
import { updateOrgConfig } from "../../slices/accountSlice";
import NextSteps from "./ConfigurationSteps/NextSteps";
import MenuItem from "@mui/material/MenuItem";

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  intro: {
    marginBottom: "10px",
  },
  step: {
    color: theme.palette.grey[600],
  },
}));

const findCurrentFiscalYear = (fiscalYears) => {
  return (
    fiscalYears.find((fiscalYear) => fiscalYear.status === "current") || {}
  );
};

export default function ConfigurationAssistantModal({ display, handleClose }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const orgConfigs = useSelector((state) => state.account.organizationConfigs);
  const fiscalYears = useSelector((state) => state.timesheets.fiscalYears);

  const [loading, setLoading] = useState(false);
  const [currentContent, setCurrentContent] = useState(<></>);
  const [currentTitle, setCurrentTitle] = useState("");
  const [progress, setProgress] = useState({ current: 0, total: 5 });
  const { t } = useTranslation("myAccount");

  const [config, setConfig] = useState({
    fiscalYear: {
      dateFrom: findCurrentFiscalYear(fiscalYears).date_from,
      dateTo: findCurrentFiscalYear(fiscalYears).date_to,
      status: "current",
      preExisting: !!findCurrentFiscalYear(fiscalYears).date_from,
    },
    timeOff: {
      unit: "day",
      period: "year",
      vacationValue: 0,
      sickValue: 0,
      sickDaysMethod: "progressive",
      sickDaysReset: "never",
      vacationDaysMethod: "progressive",
      vacationDaysReset: "never",
    },
    workingTime: {
      hours: 32,
      workedDays: [0, 1, 2, 3],
    },
    publicHolidays: {
      dates: [],
    },
    overtimeExtra: {},
  });

  useEffect(() => {
    switch (progress.current) {
      case 0:
        setCurrentContent(<Intro handleNext={handleNext} />);
        setCurrentTitle(t("config_assistant.title"));

        break;
      case 1:
        setCurrentContent(
          <FiscalYearConfig
            handleNext={handleNext}
            handlePrevious={handlePrevious}
            handleChangeConfig={handleChangeConfig}
            config={config}
          />
        );
        setCurrentTitle(`${t("config_assistant.fiscal_year.title")} `);
        break;
      case 2:
        setCurrentContent(
          <TimeOffConfig
            handleNext={handleNext}
            handlePrevious={handlePrevious}
            handleChangeConfig={handleChangeConfig}
            config={config}
          />
        );
        setCurrentTitle(`${t("config_assistant.time_off.title")} `);
        break;
      case 3:
        setCurrentContent(
          <WorkingTimeConfig
            handleNext={handleNext}
            handlePrevious={handlePrevious}
            handleChangeConfig={handleChangeConfig}
            config={config}
          />
        );
        setCurrentTitle(`${t("config_assistant.working_time.title")} `);
        break;
      case 4:
        setCurrentContent(
          <PublicHolidaysConfig
            handleNext={handleNext}
            handlePrevious={handlePrevious}
            handleChangeConfig={handleChangeConfig}
            config={config}
          />
        );
        setCurrentTitle(`${t("config_assistant.public_holidays.title")} `);
        break;
      case 5:
        setCurrentContent(
          <ConfigSummary
            handleSave={handleSave}
            handlePrevious={handlePrevious}
            config={config}
            loading={loading}
          />
        );
        setCurrentTitle(`${t("config_assistant.summary.title")} `);
        break;
      case 999:
        setCurrentContent(
          <NextSteps
            handleSave={handleSave}
            handlePrevious={handlePrevious}
            config={config}
          />
        );
        setCurrentTitle(`${t("config_assistant.next_steps.title")} `);
        break;
    }
  }, [progress, config]);

  const handleChangeConfig = (key, value) => {
    const updatedConfig = { ...config, [key]: value };
    setConfig(updatedConfig);
  };

  const handleNext = () => {
    setProgress({
      current: progress.current + 1,
      total: progress.total,
    });
  };

  const handlePrevious = () => {
    setProgress({
      current: Math.max(progress.current - 1, 0),
      total: progress.total,
    });
  };

  const handleFinish = () => {
    setProgress({
      current: 999,
      total: progress.total,
    });
  };

  const handleSave = () => {
    const fiscalYearData = config.fiscalYear;
    const orgConfigData = {
      id: orgConfigs[0].id,
      validFrom: fiscalYearData.dateFrom,
      validTo: null,
      sickValue: config.timeOff.sickValue,
      vacationValue: config.timeOff.vacationValue,
      unit: config.timeOff.unit,
      period: config.timeOff.period,
      vacationDaysMethod: config.timeOff.vacationDaysMethod,
      vacationDaysReset: config.timeOff.vacationDaysReset,
      sickDaysMethod: config.timeOff.sickDaysMethod,
      sickDaysReset: config.timeOff.sickDaysReset,
      hoursPerWeek: config.workingTime.hours,
      workedDays: config.workingTime.workedDays,
      publicHolidays: config.publicHolidays.dates,
      overtimeExtraConfig: config.overtimeExtra,
    };

    if (!config.fiscalYear.preExisting) {
      dispatch(createFiscalYear(fiscalYearData))
        .unwrap()
        .then(() =>
          dispatch(updateOrgConfig(orgConfigData))
            .unwrap()
            .then(() => handleSuccess())
            .catch((err) => handleError(err))
        )
        .catch((err) => handleError(err));
    } else {
      dispatch(updateOrgConfig(orgConfigData))
        .unwrap()
        .then(() => handleSuccess())
        .catch((err) => handleError(err));
    }
  };

  const handleSuccess = () => {
    dispatch(displayAlert(t("config_assistant.success"), "success"));
    setLoading(false);
    handleFinish();
  };

  const handleError = (err) => {
    dispatch(displayAlert(t("config_assistant.error"), "error"));
    setLoading(false);
  };

  return (
    <Dialog open={display} onClose={handleClose} fullWidth maxWidth={"md"}>
      <DialogTitle variant={"h3"}>
        {currentTitle}{" "}
        {progress.current < 100 && (
          <span className={classes.step}>
            ({t("config_assistant.step")} {progress.current} / {progress.total})
          </span>
        )}
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handleClose}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      {currentContent}
    </Dialog>
  );
}
