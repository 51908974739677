import { DialogContent, Select } from "@mui/material";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { useTranslation } from "react-i18next";
import TextField from "@mui/material/TextField";
import { useSelector } from "react-redux";
import MenuItem from "@mui/material/MenuItem";

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  actions: {
    justifyContent: "space-between",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  intro: {
    marginBottom: "10px",
  },
}));

export default function FiscalYearConfig({
  handlePrevious,
  handleNext,
  handleChangeConfig,
  config,
}) {
  const classes = useStyles();
  const { t } = useTranslation("myAccount");

  const handleClickNext = () => {
    handleNext();
  };

  const handleChange = (event) => {
    const { id, value } = event.target;
    const newFiscalYearConfig = {
      ...config.fiscalYear,
      [id]: value,
    };
    handleChangeConfig("fiscalYear", newFiscalYearConfig);
  };
  const handleChangeDay = (event) => {
    const { id, value } = event.target;
    const newFiscalYearConfig = {
      ...config.fiscalYear,
      weekFirstDay: value,
    };
    handleChangeConfig("fiscalYear", newFiscalYearConfig);
  };

  return (
    <>
      <DialogContent>
        <Grid container spacing={2} marginTop={1}>
          <Grid item xs={12}>
            <Typography type="p" variant="body1" className={classes.intro}>
              {t("config_assistant.fiscal_year.1")}
            </Typography>
          </Grid>
          <Grid item sm={12}>
            <form onChange={handleChange}>
              <Grid container spacing={2}>
                <Grid item sm={12} md={3}>
                  <TextField
                    type={"date"}
                    value={config.fiscalYear.dateFrom}
                    label={t("from")}
                    InputLabelProps={{ shrink: true }}
                    id={"dateFrom"}
                    fullWidth
                  />
                </Grid>
                <Grid item sm={12} md={3}>
                  <TextField
                    type={"date"}
                    value={config.fiscalYear.dateTo}
                    label={t("to")}
                    InputLabelProps={{ shrink: true }}
                    id={"dateTo"}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </form>
          </Grid>
          <Grid item xs={12}>
            <Typography type="p" variant="body1" className={classes.intro}>
              {t("config_assistant.fiscal_year.2")}
            </Typography>
          </Grid>
          <Grid item sm={12}>
            <form onChange={handleChangeDay}>
              <Select
                id="weekFirstDay"
                name="weekFirstDay"
                label={t("status")}
                defaultValue={"0"}
                onChange={handleChangeDay}
              >
                <MenuItem key="0" value="0" id="0">
                  {t("fiscal_years.week_starts_on.0")}
                </MenuItem>
                <MenuItem key="1" value="1" id="1">
                  {t("fiscal_years.week_starts_on.1")}
                </MenuItem>
                <MenuItem key="2" value="2" id="2">
                  {t("fiscal_years.week_starts_on.2")}
                </MenuItem>
                <MenuItem key="3" value="3" id="3">
                  {t("fiscal_years.week_starts_on.3")}
                </MenuItem>
                <MenuItem key="4" value="4" id="4">
                  {t("fiscal_years.week_starts_on.4")}
                </MenuItem>
                <MenuItem key="5" value="5" id="5">
                  {t("fiscal_years.week_starts_on.5")}
                </MenuItem>
                <MenuItem key="6" value="6" id="6">
                  {t("fiscal_years.week_starts_on.6")}
                </MenuItem>
              </Select>
            </form>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions className={classes.actions}>
        <Button
          variant="outlined"
          component="label"
          color="darkSecondary"
          onClick={handlePrevious}
        >
          {t("config_assistant.previous")}
        </Button>
        <Button
          variant="contained"
          component="label"
          color="primary"
          onClick={handleClickNext}
          disabled={!config.fiscalYear.dateFrom || !config.fiscalYear.dateTo}
        >
          {t("config_assistant.next")}
        </Button>
      </DialogActions>
    </>
  );
}
