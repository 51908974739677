import CardHeader from "@mui/material/CardHeader";
import { Card, CardContent, Grid, Switch } from "@mui/material";

import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { InfoBox } from "../../../Layout/InfoBox/InfoBox";
import Typography from "@mui/material/Typography";
import { useDispatch } from "react-redux";
import { updateOrganization } from "../../../../slices/accountSlice";
import { displayAlert } from "../../../../slices/feedbackSlice";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Input from "@mui/material/Input";
import TextField from "@mui/material/TextField";

export default function OrganizationOptions({ organization }) {
  const { t } = useTranslation("myAccount");

  const [newOrganization, setNewOrganization] = useState(organization);
  const dispatch = useDispatch();

  const handleChange = (event) => {
    const { id, checked } = event.target;
    const options = {
      ...newOrganization.options,
      [id]: checked,
    };
    const updatedOrganization = { ...organization, options };
    setNewOrganization(updatedOrganization);
    dispatch(updateOrganization(updatedOrganization))
      .unwrap()
      .then(() => {
        setNewOrganization(organization);
        dispatch(displayAlert(t("options.success"), "success"));
      })
      .catch(() => {
        setNewOrganization(organization);
      });
  };
  const handleChangeTimesheetColumns = (event) => {
    const { id, type, checked, value } = event.target;
    let timesheet_columns;
    if (type === "checkbox") {
      timesheet_columns = {
        ...newOrganization.timesheet_columns,
        [id]: checked,
      };
    } else {
      timesheet_columns = {
        ...newOrganization.timesheet_columns,
        [id]: value,
      };
    }

    console.log("coucou", type, timesheet_columns);
    const updatedOrganization = { ...organization, timesheet_columns };
    setNewOrganization(updatedOrganization);
    dispatch(updateOrganization(updatedOrganization))
      .unwrap()
      .then(() => {
        setNewOrganization(organization);
        dispatch(displayAlert(t("options.success"), "success"));
      })
      .catch(() => {
        setNewOrganization(organization);
      });
  };

  return (
    <Card>
      <CardHeader
        title={t("options.title")}
        titleTypographyProps={{ variant: "h3" }}
      />
      <CardContent>
        <Card>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item sm={12}>
                <Typography variant="h4" marginBottom={2}>
                  {t("options.projects.title")}
                </Typography>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        id={"projects"}
                        checked={newOrganization?.options?.projects}
                        onChange={handleChange}
                      />
                    }
                    label={t("options.projects.activate")}
                  />

                  <FormControlLabel
                    control={
                      <Switch
                        id={"projects_mandatory"}
                        checked={newOrganization?.options?.projects_mandatory}
                        onChange={handleChange}
                      />
                    }
                    label={t("options.projects.projects_status")}
                  />
                </FormGroup>
              </Grid>
              <Grid item sm={12}>
                <InfoBox level="info">{t("options.projects.info")}</InfoBox>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Card>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item sm={12}>
                <Typography variant="h4" marginBottom={2}>
                  {t("options.prefill_timesheets.title")}
                </Typography>
                <Switch
                  id={"prefill_timesheets"}
                  checked={newOrganization?.options?.prefill_timesheets}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item sm={12}>
                <InfoBox level="info">
                  {t("options.prefill_timesheets.info")}
                </InfoBox>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Card>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item sm={12}>
                <Typography variant="h4" marginBottom={2}>
                  {t("options.family_days.title")}
                </Typography>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        id={"has_family_hours"}
                        checked={
                          newOrganization?.timesheet_columns?.has_family_hours
                        }
                        onChange={handleChangeTimesheetColumns}
                      />
                    }
                    label={t("options.family_days.activate")}
                  />
                </FormGroup>
              </Grid>

              {newOrganization?.timesheet_columns?.has_family_hours ? (
                <>
                  <Grid item sm={12}>
                    <Typography variant="h5" marginBottom={1}>
                      {t("options.family_days.name")}
                    </Typography>
                    <TextField
                      type="text"
                      id="family_hours_custom_name"
                      variant="outlined"
                      // label={t("options.family_days.name")}
                      required
                      value={
                        newOrganization?.timesheet_columns
                          ?.family_hours_custom_name
                      }
                      onChange={handleChangeTimesheetColumns}
                    ></TextField>{" "}
                  </Grid>
                  <Grid item sm={12}>
                    <InfoBox level="info">
                      {t("options.family_days.info2")}
                    </InfoBox>
                  </Grid>
                </>
              ) : (
                <Grid item sm={12}>
                  <InfoBox level="info">
                    {t("options.family_days.info")}
                  </InfoBox>
                </Grid>
              )}
            </Grid>
          </CardContent>
        </Card>
      </CardContent>
    </Card>
  );
}
