import React from "react";
import Typography from "@mui/material/Typography";
import dayjs from "dayjs";
var localizedFormat = require("dayjs/plugin/localizedFormat");
dayjs.extend(localizedFormat);
import i18n from "../i18n";
import i18next from "i18next";

i18next.loadNamespaces("errors", (err, t) => {
  /* ... */
});
export const parseDRFError = (error) => {
  if (error.response && error.response.status === 400) {
    const { data } = error.response;
    if (data.email && data.email.includes("Ce champ ne peut être vide.")) {
      return i18n.t("errors:email.empty");
    }
    if (
      data.email &&
      data.email.includes(
        "Un objet user avec ce champ adresse email existe déjà."
      )
    ) {
      return i18n.t("errors:email.existing");
    } else if (
      data.contract_start_date &&
      data.contract_start_date.includes(
        "La date n'a pas le bon format. Utilisez un des formats suivants : YYYY-MM-DD."
      )
    ) {
      return i18n.t("errors:contract_start_date.invalid");
    } else {
      return i18n.t("errors:unknown");
    }
  }
  if (error.response && error.response.status === 401) {
    const { data } = error.response;
    return i18n.t("errors:unauthorized");
  }
  return i18n.t("error:unknown");
};

export const replaceObjectWithKey = (array, newObject, key) => {
  return array.map((oldObject) => {
    if (oldObject[key] === newObject[key]) {
      return newObject;
    }
    return oldObject;
  });
};

export const removeObjectWithKey = (array, newObject, key) =>
  array.filter((oldObject) => oldObject[key] !== newObject[key]);

export const round = (number) => {
  return Math.round(number * 100) / 100;
};

export const setLocale = (locale) => {
  dayjs.locale(locale);
};
export const formatDate = (date) => {
  return dayjs(date).format("LL");
};

export const getDay = (date) => {
  return new Date(date).getDate();
};

export const displayStatus = (timesheet, t, projectsHoursMandatory) => {
  let status;
  let color;

  switch (timesheet.status) {
    case "empty":
      if (new Date(timesheet.date_to) <= new Date()) {
        status = "late";
        color = "secondary.dark";
      } else {
        status = "empty";
        color = "inherit";
      }
      break;
    case "filled":
      if (new Date(timesheet.date_to) <= new Date()) {
        status = "to_sign";
        color = "secondary.dark";
      } else {
        status = "filled";
        color = "inherit";
      }
      break;
    case "signed":
      if (new Date(timesheet.date_to) <= new Date()) {
        status = "to_countersign";
        color = "secondary.dark";
      } else {
        status = "signed";
        color = "inherit";
      }
      break;
    case "countersigned":
      status = "countersigned";
      color = "primary.darkest";
      break;
    case "unpaid_leave":
      status = "unpaid_leave";
      color = "primary.darkest";
      break;
    case "parental_leave":
      status = "parental_leave";
      color = "primary.darkest";
      break;

    default:
      status = "unknown";
  }

  let project_status;
  let project_color;
  switch (timesheet.summary?.project_hours_filled) {
    case true:
      project_status = "completed";
      project_color = "primary.dark";

      break;
    case false:
      project_status = "empty";
      if (status === "empty") {
        project_color = "inherit";
      } else {
        project_color = "secondary.dark";
      }
      break;
    default:
      project_status = "unknown";
  }

  return (
    <>
      <Typography variant="h5" color={color}>
        <span style={{ color: "black" }}>{t("status")} :</span> {t(status)}
      </Typography>{" "}
      {projectsHoursMandatory ? (
        <Typography variant="h5" color={project_color} marginTop={1}>
          <span style={{ color: "black" }}>{t("project_status")} :</span>{" "}
          {t(project_status)}
        </Typography>
      ) : (
        ""
      )}
    </>
  );
};

export const addYears = (date, years) => {
  const new_date = new Date(date);
  new_date.setFullYear(new_date.getFullYear() + 1);
  return new_date;
};
