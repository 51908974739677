import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import AccountService from "../services/account.service";
import { displayAlert } from "./feedbackSlice";
import { parseDRFError, replaceObjectWithKey } from "../utils/helpers";
import i18n from "../i18n";

const getAdminStatusFromLocalStorage = () =>
  localStorage.getItem("isAdmin", false) == "true";
const setAdminStatusToLocalStorage = (status) => {
  localStorage.setItem("isAdmin", status);
};
const getTeamAdminStatusFromLocalStorage = () =>
  localStorage.getItem("isTeamAdmin", false) == "true";
const setTeamAdminStatusToLocalStorage = (status) => {
  localStorage.setItem("isTeamAdmin", status);
};

// Organization
export const getOrganization = createAsyncThunk(
  "account/organization/get",
  async (thunkAPI) => {
    try {
      const data = await AccountService.getOrganization();
      return { configs: data.data.configs, organization: data.data };
    } catch (error) {
      const message = parseDRFError(error);
      thunkAPI.dispatch(displayAlert(message, "error"));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const updateOrganization = createAsyncThunk(
  "account/organization/put",
  async (organizationData, thunkAPI) => {
    try {
      const data = await AccountService.updateOrganization(organizationData);
      return { organization: data.data };
    } catch (error) {
      const message = parseDRFError(error);
      thunkAPI.dispatch(displayAlert(message, "error"));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const createOrgConfig = createAsyncThunk(
  "account/organization/configs/post",
  async (orgData, thunkAPI) => {
    try {
      const data = await AccountService.createOrgConfig(orgData);
      return { config: data.data };
    } catch (error) {
      const message = parseDRFError(error);
      thunkAPI.dispatch(displayAlert(message, "error"));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const updateOrgConfig = createAsyncThunk(
  "account/organization/config/put",
  async (orgData, thunkAPI) => {
    try {
      const data = await AccountService.updateOrgConfig(orgData);
      return { config: data.data };
    } catch (error) {
      const message = parseDRFError(error);
      thunkAPI.dispatch(displayAlert(message, "error"));
      return thunkAPI.rejectWithValue();
    }
  }
);

// Teams
export const getTeams = createAsyncThunk(
  "account/teams/get",
  async (thunkAPI) => {
    try {
      const data = await AccountService.getTeams();
      return { teams: data.data };
    } catch (error) {
      const message = parseDRFError(error);
      thunkAPI.dispatch(displayAlert(message, "error"));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const createTeam = createAsyncThunk(
  "teams/post",
  async (teamData, thunkAPI) => {
    try {
      const data = await AccountService.createTeam(teamData);
      return { team: data.data };
    } catch (error) {
      const message = parseDRFError(error);
      thunkAPI.dispatch(displayAlert(message, "error"));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const updateTeam = createAsyncThunk(
  "teams/put",
  async (teamData, thunkAPI) => {
    try {
      const data = await AccountService.updateTeam(teamData);
      return { team: data.data };
    } catch (error) {
      const message = parseDRFError(error);
      thunkAPI.dispatch(displayAlert(message, "error"));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const deleteTeam = createAsyncThunk(
  "teams/delete",
  async (teamId, thunkAPI) => {
    try {
      await AccountService.deleteTeam(teamId);
      return { teamId: teamId };
    } catch (error) {
      const message = parseDRFError(error);
      thunkAPI.dispatch(displayAlert(message, "error"));
      return thunkAPI.rejectWithValue();
    }
  }
);

// Users
export const getUsers = createAsyncThunk(
  "account/users/get",
  async ({ displayArchived }, thunkAPI) => {
    try {
      const data = await AccountService.getUsers({ displayArchived });
      return { users: data.data };
    } catch (error) {
      const message = parseDRFError(error);
      thunkAPI.dispatch(displayAlert(message, "error"));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getUser = createAsyncThunk(
  "account/user/get",
  async (userId, thunkAPI) => {
    try {
      const data = await AccountService.getUser(userId);
      return { user: data.data };
    } catch (error) {
      const message = parseDRFError(error);
      thunkAPI.dispatch(displayAlert(message, "error"));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getSelf = createAsyncThunk(
  "account/self/get",
  async (thunkAPI) => {
    try {
      const data = await AccountService.getSelf();
      return { self: data.data };
    } catch (error) {
      const message = parseDRFError(error);
      thunkAPI.dispatch(displayAlert(message, "error"));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getInvitedUser = createAsyncThunk(
  "account/signup-user/get",
  async (token, thunkAPI) => {
    try {
      const data = await AccountService.getInvitedUser(token);
      return { user: data.data };
    } catch (error) {
      const message = parseDRFError(error);
      thunkAPI.dispatch(displayAlert(message, "error"));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const signupUser = createAsyncThunk(
  "account/signup-user/post",
  async (userData, thunkAPI) => {
    try {
      const data = await AccountService.signupUser(userData);
      return { user: data.data };
    } catch (error) {
      const message = parseDRFError(error);
      thunkAPI.dispatch(displayAlert(message, "error"));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const inviteUser = createAsyncThunk(
  "account/invite-user/post",
  async (userData, thunkAPI) => {
    try {
      const data = await AccountService.inviteUser(userData);
      return { user: data.data };
    } catch (error) {
      const message = parseDRFError(error);
      thunkAPI.dispatch(displayAlert(message, "error"));
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updateUser = createAsyncThunk(
  "account/update-user/put",
  async (userData, thunkAPI) => {
    try {
      const data = await AccountService.updateUser(userData);
      return { user: data.data };
    } catch (error) {
      const message = parseDRFError(error);
      thunkAPI.dispatch(displayAlert(message, "error"));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const updateUserConfig = createAsyncThunk(
  "account/update-user-config/put",
  async (userConfig, thunkAPI) => {
    try {
      const data = await AccountService.updateUserConfig(userConfig);
      return { config: data.data };
    } catch (error) {
      const message = parseDRFError(error);
      thunkAPI.dispatch(displayAlert(message, "error"));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const createUserConfig = createAsyncThunk(
  "account/create-user-config/post",
  async (userConfig, thunkAPI) => {
    try {
      const data = await AccountService.createUserConfig(userConfig);
      return { config: data.data };
    } catch (error) {
      const message = parseDRFError(error);
      thunkAPI.dispatch(displayAlert(message, "error"));
      return thunkAPI.rejectWithValue();
    }
  }
);

const initialState = {
  organizationConfigs: [],
  organization: {},
  organizationOptions: [],
  organizationPlanType: "unsubscribed",
  teams: [],
  users: [],
  self: {},
  isAdmin: getAdminStatusFromLocalStorage(),
  isTeamAdmin: getTeamAdminStatusFromLocalStorage(),
};

export const accountSlice = createSlice({
  name: "account",
  initialState: initialState,
  reducers: {},
  extraReducers: {
    // SignupUser
    [signupUser.fulfilled]: (state, action) => {
      state.isLoggedIn = false;
    },
    // Organization
    [createOrgConfig.fulfilled]: (state, action) => {
      state.organizationConfigs = [
        action.payload.config,
        ...state.organizationConfigs,
      ];
    },
    [getOrganization.fulfilled]: (state, action) => {
      state.organizationConfigs = action.payload.configs;
      state.organization = action.payload.organization;
      state.organizationPlanType = action.payload.organization.plan_type;
      state.organizationOptions = Object.entries(
        action.payload.organization.options
      ).reduce((acc, [key, value]) => {
        if (value) {
          acc.push(key);
        }
        return acc;
      }, []);
    },
    [updateOrganization.fulfilled]: (state, action) => {
      state.organization = action.payload.organization;

      state.organizationOptions = Object.entries(
        action.payload.organization.options
      ).reduce((acc, [key, value]) => {
        if (value) {
          acc.push(key);
        }
        return acc;
      }, []);
    },

    // Teams
    [getTeams.fulfilled]: (state, action) => {
      const newTeams = action.payload.teams;
      newTeams.sort((a, b) => b.name.toLowerCase() < a.name.toLowerCase());
      state.teams = newTeams;
    },
    [createTeam.fulfilled]: (state, action) => {
      state.teams = [action.payload.team, ...state.teams];
    },
    [updateTeam.fulfilled]: (state, action) => {
      const updatedTeam = action.payload.team;
      state.teams = replaceObjectWithKey(state.teams, updatedTeam, "id");
    },
    [deleteTeam.fulfilled]: (state, action) => {
      const teamId = action.payload.teamId;
      state.teams = [...state.teams].filter((team) => team.id !== teamId);
    },

    // Users
    [getUsers.fulfilled]: (state, action) => {
      const newUsers = action.payload.users;
      newUsers.sort(
        (a, b) => b.first_name.toLowerCase() < a.first_name.toLowerCase()
      );
      state.users = newUsers;
    },

    [getSelf.fulfilled]: (state, action) => {
      const self = action.payload.self;
      state.self = self;
      if (self.is_admin) {
        state.isAdmin = true;
        setAdminStatusToLocalStorage(true);
      } else {
        setAdminStatusToLocalStorage(false);
      }
      if (self.is_team_admin) {
        state.isTeamAdmin = true;
        setTeamAdminStatusToLocalStorage(true);
      } else {
        setTeamAdminStatusToLocalStorage(false);
      }
    },
    [getInvitedUser.fulfilled]: (state, action) => {
      state.invitedUser = action.payload.user;
    },
    [inviteUser.fulfilled]: (state, action) => {
      const newUsers = [action.payload.user, ...state.users];
      newUsers.sort(
        (a, b) => b.first_name.toLowerCase() < a.first_name.toLowerCase()
      );
      state.users = newUsers;
    },
    [updateUser.fulfilled]: (state, action) => {
      const newUser = action.payload.user;
      const newUsers = replaceObjectWithKey(state.users, newUser, "email");
      if (newUser.id === state.self.id) {
        state.self = newUser;
      }
      newUsers.sort(
        (a, b) => b.first_name.toLowerCase() < a.first_name.toLowerCase()
      );
      state.users = newUsers;
    },
    [getUser.fulfilled]: (state, action) => {
      const newUser = action.payload.user;
      if (state.users.length) {
        const newUsers = replaceObjectWithKey(state.users, newUser, "email");
        newUsers.sort(
          (a, b) => b.first_name.toLowerCase() < a.first_name.toLowerCase()
        );
        state.users = newUsers;
      } else {
        state.users = [newUser];
      }
      if (newUser.id === state.self.id) {
        state.self = newUser;
      }
    },
    [updateUserConfig.fulfilled]: (state, action) => {
      const config = action.payload.config;
      const user = state.users.find((user) => user.id === config.user_id);
      user.configs = replaceObjectWithKey(user.configs, config, "id");
      state.users = replaceObjectWithKey(state.users, user, "id");
    },
    [createUserConfig.fulfilled]: (state, action) => {
      const config = action.payload.config;
      const user = state.users.find((user) => user.id === config.user_id);
      user.configs.push(config);
      state.users = replaceObjectWithKey(state.users, user, "id");
    },
    [updateOrgConfig.fulfilled]: (state, action) => {
      const config = action.payload.config;
      const configs = state.organizationConfigs;
      const newConfigs = replaceObjectWithKey(configs, config, "id");
      state.organizationConfigs = newConfigs;
      state.organization.configs = newConfigs;
    },
  },
});

export default accountSlice.reducer;
