import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import { LoadingButton } from "@mui/lab";
import Grid from "@mui/material/Grid";
import { useDispatch, useSelector } from "react-redux";

import { displayAlert } from "../../../../../slices/feedbackSlice";
import { formatDate, round } from "../../../../../utils/helpers";
import TextField from "@mui/material/TextField";
import {
  updateUserConfig,
  createUserConfig,
} from "../../../../../slices/accountSlice";
import { InfoBox } from "../../../../Layout/InfoBox/InfoBox";
import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  actions: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "1rem",
  },
  summary: {
    padding: "0.75rem",
  },
  infoBox: {
    marginTop: "1.5rem",
  },
  accordion: {
    marginBottom: "1rem",
  },
}));

export default function UserConfig({
  config,
  orgConfig,
  newConfig,
  userId,
  refreshUser,
  handleClose,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [fields, setFields] = useState({});
  const [displaySpecificParams, setDisplaySpecificParams] = useState(
    config.is_specific
  );
  const [expanded, setExpanded] = useState(!!newConfig);
  const [loading, setLoading] = useState(false);
  const [isNewConfig, setIsNewConfig] = useState(newConfig);

  const organization = useSelector((state) => state.account.organization);
  const organizationConfigs = useSelector(
    (state) => state.account.organizationConfigs
  );
  const [workedDays, setWorkedDays] = useState([]);

  const { t, i18n } = useTranslation(["users", "common"]);
  const locale = i18n.language;

  const daysOfWeek = [
    { id: 0, label: t("Monday", { ns: "common" }) },
    { id: 1, label: t("Tuesday", { ns: "common" }) },
    { id: 2, label: t("Wednesday", { ns: "common" }) },
    { id: 3, label: t("Thursday", { ns: "common" }) },
    { id: 4, label: t("Friday", { ns: "common" }) },
    { id: 5, label: t("Saturday", { ns: "common" }) },
    { id: 6, label: t("Sunday", { ns: "common" }) },
  ];

  const formatAutocompleteDaysList = (dayIds) => {
    return dayIds.map((id) =>
      daysOfWeek.find((dayOfWeek) => dayOfWeek.id === id)
    );
  };

  useEffect(() => {
    let orderedWorkedDays;
    if (displaySpecificParams) {
      orderedWorkedDays = [...config.worked_days];
    } else {
      orderedWorkedDays = [...orgConfig.worked_days];
    }
    orderedWorkedDays.sort((a, b) => a - b);
    const formattedWorkedDays = formatAutocompleteDaysList(orderedWorkedDays);
    setWorkedDays(formattedWorkedDays);
  }, [config, displaySpecificParams]);

  const fillFieldsFromConfig = (t, config, orgConfig) => {
    setFields({
      validFrom: {
        type: "date",
        variant: "outlined",
        required: true,
        fullWidth: true,
        name: "validFrom",
        label: t("From"),
        id: "validFrom",
        // value: config.valid_from || new Date().toISOString().slice(0, 10),
        value: config.valid_from ? dayjs(config.valid_from) : dayjs(new Date()),
        // InputLabelProps: { shrink: true },
      },
      unit: {
        variant: "outlined",
        required: true,
        fullWidth: true,
        name: "unit",
        label: t("time_unit"),
        id: "unit",
        value: orgConfig.vacation_time_per_period.unit || "error",
      },
      period: {
        variant: "outlined",
        required: true,
        fullWidth: true,
        name: "period",
        label: t("time_period"),
        id: "period",
        value: orgConfig.vacation_time_per_period.period || "error",
      },
      vacationValue: {
        type: "number",
        variant: "outlined",
        required: true,
        fullWidth: true,
        id: "vacationValue",
        label: t(
          `plural.${
            config.vacation_time_per_period?.unit
              ? config.vacation_time_per_period?.unit
              : ""
          }`
        ),
        name: "vacationValue",
        value: config.vacation_time_per_period?.time
          ? round(config.vacation_time_per_period.time)
          : 0,
        InputLabelProps: { shrink: true },
      },
      sickValue: {
        type: "number",
        variant: "outlined",
        required: true,
        fullWidth: true,
        id: "sickValue",
        label: t(
          `plural.${
            config.sick_time_per_period?.unit
              ? config.sick_time_per_period?.unit
              : ""
          }`
        ),
        name: "sickValue",
        value: config.sick_time_per_period?.time
          ? round(config.sick_time_per_period.time)
          : 0,
        InputLabelProps: { shrink: true },
      },
      familyValue: {
        type: "number",
        variant: "outlined",
        required: true,
        fullWidth: true,
        id: "familyValue",
        label: t("days"),
        name: "familyValue",
        value: config.family_days_per_year
          ? round(config.family_days_per_year)
          : 0,
        InputLabelProps: { shrink: true },
      },
      hoursPerWeek: {
        type: "number",
        variant: "outlined",
        required: true,
        fullWidth: true,
        id: "hoursPerWeek",
        label: t("plural.hour"),
        name: "hoursPerWeek",
        value: config.worked_hours_per_week || "",
        InputProps: {
          inputProps: {
            max: 24 * 7,
            min: 0,
          },
        },
        InputLabelProps: { shrink: true },
      },
      maxCumulatedSickHours: {
        type: "number",
        variant: "outlined",
        required: true,
        fullWidth: true,
        id: "maxCumulatedSickHours",
        label: t("rules.hours"),
        name: "maxCumulatedSickHours",
        value: config.max_cumulated_sick_hours,
        InputProps: {
          inputProps: {
            min: 0,
          },
        },
        InputLabelProps: { shrink: true },
      },
      overtimeExtraConfig: {
        type: "number",
        variant: "outlined",
        required: true,
        fullWidth: true,
        id: "overtimeExtraConfig",
        label: t("rules.hours"),
        name: "overtimeExtraConfig",
        value: config.overtime_xtra_config,
        InputLabelProps: { shrink: true },
      },
      compensatoryDaysPerYear: {
        type: "number",
        variant: "outlined",
        required: true,
        fullWidth: true,
        id: "compensatoryDaysPerYear",
        label: t("rules.hours"),
        name: "compensatoryDaysPerYear",
        value: config.compensatory_days_per_year,
        InputLabelProps: { shrink: true },
      },
    });
  };

  useEffect(() => {
    fillFieldsFromConfig(t, config, orgConfig);
  }, [t, config, orgConfig]);

  const handleChange = (event) => {
    event.preventDefault();
    const { target } = event;
    const { id, value } = target;
    setFields({
      ...fields,
      [id]: {
        ...fields[id],
        value,
      },
    });
  };

  const handleChangeDate = (value, id) => {
    setFields({
      ...fields,
      [id]: {
        ...fields[id],
        value,
      },
    });
  };

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  const handleSuccess = () => {
    dispatch(displayAlert(t("rules_update_success"), "success"));
    setIsNewConfig(false);
    setLoading(false);
    refreshUser();
    setExpanded(false);
  };

  const handleError = (err) => {
    dispatch(displayAlert(t("error"), "error"));
    setLoading(false);
  };

  const handleCancel = () => {
    fillFieldsFromConfig(t, config, orgConfig);
    toggleExpanded();
  };

  const handleSave = () => {
    setLoading(true);
    const userConfig = Object.values(fields).reduce((acc, elem) => {
      acc[elem.id] = elem.value;
      return acc;
    }, {});

    userConfig.id = config.id;
    userConfig.userId = userId;
    userConfig.isSpecific = displaySpecificParams;
    userConfig.workedDays = workedDays.map((workedDay) => workedDay.id);
    userConfig.daysPerWeek = workedDays.length;

    userConfig.validFrom = userConfig.validFrom.format("YYYY-MM-DD");

    if (!displaySpecificParams) {
      // If the specific params are not displayed,
      // we set these values to null top prevent from overriding default values
      userConfig.sickValue = null;
      userConfig.vacationValue = null;
      userConfig.daysPerWeek = null;
      userConfig.hoursPerWeek = null;
    }

    isNewConfig
      ? dispatch(createUserConfig(userConfig))
          .unwrap()
          .then(() => handleSuccess())
          .catch((err) => handleError(err))
      : dispatch(updateUserConfig(userConfig))
          .unwrap()
          .then(() => handleSuccess())
          .catch((err) => handleError(err));
  };

  const handleDefineSpecificRules = () => {
    setFields({
      ...fields,
      sickValue: {
        ...fields.sickValue,
        value: orgConfig.sick_time_per_period.time,
      },
      hoursPerWeek: {
        ...fields.hoursPerWeek,
        value: orgConfig.worked_hours_per_week,
      },
      vacationValue: {
        ...fields.vacationValue,
        value: orgConfig.vacation_time_per_period.time,
      },
      familyValue: {
        ...fields.familyValue,
        value: orgConfig.family_days_per_year,
      },
    });
    setDisplaySpecificParams(!displaySpecificParams);
  };

  const handleChangeWorkedDays = (event, selectedWorkedDays) => {
    setWorkedDays(selectedWorkedDays);
  };

  const userRules = () => {
    if (displaySpecificParams) {
      return (
        <Grid container spacing={2}>
          {/* Vacancies */}
          <Grid item xs={12}>
            <Typography component="h4" variant="h4">
              {t("vacation")}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <TextField {...fields.vacationValue} />
          </Grid>
          <Grid item xs={4} display="flex">
            <Typography
              component="p"
              variant="body1"
              align="center"
              margin="auto"
            >
              {t(`plural.${fields.unit?.value}`)} {t("by")}{" "}
              {t(`singular.${fields.period?.value}`)}
            </Typography>
          </Grid>
          {/* Sickness */}
          <Grid item xs={12}>
            <Typography component="h4" variant="h4" marginTop={"1rem"}>
              {t("sick")}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <TextField {...fields.sickValue} />
          </Grid>
          <Grid item xs={4} display="flex">
            <Typography
              component="p"
              variant="body1"
              align="center"
              margin="auto"
            >
              {t(`plural.${fields.unit?.value}`)} {t("by")}{" "}
              {t(`singular.${fields.period?.value}`)}
            </Typography>
          </Grid>
          {/* Family days */}
          {organization?.timesheet_columns.has_family_hours && (
            <>
              <Grid item xs={12}>
                <Typography component="h4" variant="h4" marginTop={"1rem"}>
                  {organization?.timesheet_columns.family_hours_custom_name}
                </Typography>
              </Grid>
              <Grid item xs={3} md={2}>
                <TextField {...fields.familyValue} onChange={handleChange} />
              </Grid>
              <Grid item xs={4} display="flex">
                <Typography
                  component="p"
                  variant="body1"
                  align="left"
                  margin="auto 0"
                >
                  {t(`days`)} {t("by")} {t("singular.year")}
                </Typography>
              </Grid>
            </>
          )}
          {/* Working time */}
          <Grid item xs={12}>
            <Typography component="h4" variant="h4" marginTop={"1rem"}>
              {t("working_time")}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <TextField {...fields.hoursPerWeek} />
          </Grid>
          <Grid item xs={4} justifyContent="center" display="flex">
            <Typography
              component="p"
              variant="body1"
              align="center"
              margin="auto"
            >
              {t("plural.hour")} {t("by")} {t("singular.week")}
            </Typography>
          </Grid>

          <Grid item xs={12} marginTop={2}>
            <Autocomplete
              multiple
              options={daysOfWeek}
              getOptionLabel={(option) => option.label}
              filterSelectedOptions
              value={workedDays}
              onChange={handleChangeWorkedDays}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label={t("worked_days", { ns: "common" })}
                />
              )}
            />
          </Grid>
          <Grid item sm={12} className={classes.infoBox}>
            <InfoBox level={"info"}>{t("specific_rules")}</InfoBox>
          </Grid>
        </Grid>
      );
    } else {
      return (
        <Grid container spacing={2}>
          {/* Vacation */}
          <Grid item xs={4}>
            <Typography component="h5" variant="h5">
              {t("vacation")}
            </Typography>
          </Grid>
          <Grid item xs={8}>
            {round(
              organizationConfigs[organizationConfigs.length - 1]
                ?.vacation_time_per_period.time || 0
            )}{" "}
            {t(
              `plural.${
                organizationConfigs[organizationConfigs.length - 1]
                  ?.vacation_time_per_period.unit
              }`
            )}{" "}
            {t("by")}{" "}
            {t(
              `singular.${
                organizationConfigs[organizationConfigs.length - 1]
                  ?.vacation_time_per_period.period
              }`
            )}
          </Grid>
          {/* Sickness */}
          <Grid item xs={4}>
            <Typography component="h5" variant="h5">
              {t("sick")}
            </Typography>
          </Grid>
          <Grid item xs={8}>
            {round(
              organizationConfigs[organizationConfigs.length - 1]
                ?.sick_time_per_period.time || 0
            )}{" "}
            {t(
              `plural.${
                organizationConfigs[organizationConfigs.length - 1]
                  ?.sick_time_per_period.unit
              }`
            )}{" "}
            {t("by")}{" "}
            {t(
              `singular.${
                organizationConfigs[organizationConfigs.length - 1]
                  ?.sick_time_per_period.period
              }`
            )}
          </Grid>

          <Grid item xs={4}>
            <Typography component="h5" variant="h5">
              {t("working_time")}
            </Typography>
          </Grid>
          <Grid item xs={8}>
            {
              organizationConfigs[organizationConfigs.length - 1]
                ?.worked_hours_per_week
            }{" "}
            {t(`plural.hour`)} {t("by")} {t(`singular.week`)} {t("in")}{" "}
            {workedDays.length} {t(`plural.day`)}
          </Grid>
          <Grid item xs={4}>
            <Typography component="h5" variant="h5">
              {t("worked_days", { ns: "common" })}
            </Typography>
          </Grid>
          <Grid item xs={8}>
            {workedDays.map((workedDay) => (
              <Typography key={workedDay.id}>{workedDay.label}</Typography>
            ))}
          </Grid>
          <Grid item sm={12} className={classes.infoBox}>
            <InfoBox level={"info"}>{t("global_rules")}</InfoBox>
          </Grid>
        </Grid>
      );
    }
  };

  const periodEdit = () => {
    return (
      <>
        <Grid item sm={12}>
          <Grid container spacing={2}>
            <Grid item sm={12} md={6}>
              <DatePicker
                {...fields.validFrom}
                firstDayOfWeek={0}
                onChange={(value) => handleChangeDate(value, "validFrom")}
              />
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  };

  return (
    <Accordion
      expanded={expanded}
      onChange={toggleExpanded}
      className={classes.accordion}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
      >
        {!expanded && (
          <>
            <Typography
              variant={"body1"}
              component={"span"}
              className={classes.summary}
            >
              {t("From")}
            </Typography>
            <Typography
              variant={"h5"}
              component={"span"}
              className={classes.summary}
            >
              {formatDate(fields.validFrom?.value || config.valid_from, locale)}
            </Typography>
          </>
        )}
      </AccordionSummary>
      <AccordionDetails>
        <form onChange={handleChange}>
          <Grid container spacing={3}>
            {expanded && periodEdit()}
            <Grid item sm={12}>
              {userRules()}
            </Grid>
            <Grid item sm={12}>
              <Button
                variant="outlined"
                component="label"
                color="darkSecondary"
                onClick={handleDefineSpecificRules}
              >
                {displaySpecificParams
                  ? t("apply_team_rules")
                  : t("define_specific_rules")}
              </Button>
            </Grid>
          </Grid>
        </form>
      </AccordionDetails>
      <AccordionActions className={classes.actions}>
        <Button
          variant="contained"
          component="label"
          color="secondary"
          onClick={handleCancel}
          sx={{ height: "100%" }}
        >
          {t("cancel")}
        </Button>
        <LoadingButton
          loading={loading}
          variant="contained"
          component="label"
          color="primary"
          onClick={handleSave}
          sx={{ height: "100%" }}
        >
          {t("save")}
        </LoadingButton>
      </AccordionActions>
    </Accordion>
  );
}
