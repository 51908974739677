import { Grid, Select } from "@mui/material";
import TextField from "@mui/material/TextField";
import { LoadingButton } from "@mui/lab";
import React, { useEffect, useState } from "react";
import { createFiscalYear } from "../../../../../slices/timesheetsSlice";
import { displayAlert } from "../../../../../slices/feedbackSlice";
import MenuItem from "@mui/material/MenuItem";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { addYears } from "../../../../../utils/helpers";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";

export default function NewFiscalYear(handleCancel) {
  const [loading, setLoading] = useState(false);
  const [fields, setFields] = useState({});
  const dispatch = useDispatch();
  const fiscalYears = useSelector((state) => state.timesheets.fiscalYears);

  const { t } = useTranslation("myAccount");

  useEffect(() => {
    let lastFiscalYear = {
      date_from: new Date("2021-04-01"),
      date_to: new Date("2022-03-31"),
    };
    if (fiscalYears.length) {
      lastFiscalYear = [...fiscalYears].sort(
        (a, b) => new Date(a.date_from) - new Date(b.date_from)
      )[0];
    }
    setFields({
      status: {
        autoComplete: "status",
        name: "status",
        variant: "outlined",
        required: true,
        select: true,
        fullWidth: true,
        id: "status",
        label: t("status"),
        value: "current",
        InputLabelProps: { shrink: true },
        children: [
          <MenuItem key="current" value="current" id="current">
            {t("current")}
          </MenuItem>,
          <MenuItem key="future" value="future" id="future">
            {t("future")}
          </MenuItem>,
          <MenuItem key="archived" value="archived" id="archived">
            {t("archived")}
          </MenuItem>,
        ],
      },
      dateFrom: {
        name: "dateFrom",
        type: "date",
        variant: "outlined",
        required: true,
        fullWidth: true,
        id: "dateFrom",
        label: t("from"),
        value: addYears(lastFiscalYear.date_from, 1)
          .toISOString()
          .split("T")[0],
        InputLabelProps: { shrink: true },
      },
      dateTo: {
        name: "dateTo",
        type: "date",
        variant: "outlined",
        required: true,
        fullWidth: true,
        id: "dateTo",
        label: t("to"),
        value: addYears(lastFiscalYear.date_to, 1).toISOString().split("T")[0],
        InputLabelProps: { shrink: true },
      },
      weekFirstDay: {
        autoComplete: "weekFirstDay",
        name: "weekFirstDay",
        variant: "outlined",
        required: true,
        select: true,
        fullWidth: false,
        id: "weekFirstDay",
        label: t("fiscal_years.week_first_day"),
        value: lastFiscalYear.week_first_day || 0,
        InputLabelProps: { shrink: true },
        children: [
          <MenuItem key="0" value="0" id="0">
            {t("fiscal_years.week_starts_on.0")}
          </MenuItem>,
          <MenuItem key="1" value="1" id="1">
            {t("fiscal_years.week_starts_on.1")}
          </MenuItem>,
          <MenuItem key="2" value="2" id="2">
            {t("fiscal_years.week_starts_on.2")}
          </MenuItem>,
          <MenuItem key="3" value="3" id="3">
            {t("fiscal_years.week_starts_on.3")}
          </MenuItem>,
          <MenuItem key="4" value="4" id="4">
            {t("fiscal_years.week_starts_on.4")}
          </MenuItem>,
          <MenuItem key="5" value="5" id="5">
            {t("fiscal_years.week_starts_on.5")}
          </MenuItem>,
          <MenuItem key="6" value="6" id="6">
            {t("fiscal_years.week_starts_on.6")}
          </MenuItem>,
        ],
      },
    });
  }, [t, fiscalYears]);

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    const fiscalYearData = Object.values(fields).reduce((acc, elem) => {
      acc[elem.id] = elem.value;
      return acc;
    }, []);

    dispatch(createFiscalYear(fiscalYearData))
      .unwrap()
      .then(() => handleSuccess())
      .catch((err) => handleError(err));
  };

  const handleSuccess = () => {
    dispatch(displayAlert(t("fiscal_years.success"), "success"));
    setLoading(false);
  };

  const handleError = (err) => {
    dispatch(displayAlert(err.message, "error"));
    setLoading(false);
  };

  const handleChange = (event) => {
    event.preventDefault();
    const { target } = event;
    const { id, value } = target;
    setFields({
      ...fields,
      [id]: {
        ...fields[id],
        value,
      },
    });
  };
  const handleChangeDay = (event) => {
    event.preventDefault();
    const { target } = event;
    const { id, value } = target;
    setFields({
      ...fields,
      weekFirstDay: {
        ...fields.weekFirstDay,
        value,
      },
    });
  };
  // const handleSelect = (event) => {
  //   event.preventDefault();
  //   const { target } = event;
  //   const { name, value } = target;
  //   setFields({
  //     ...fields,
  //     [name]: {
  //       ...fields[name],
  //       value,
  //     },
  //   });
  // };

  return (
    <>
      <Grid item sm={12} marginTop={2}>
        <Divider variant="fullWidth" />
        <Typography type="h5" variant="h5" marginTop={2}>
          {t("config_assistant.fiscal_year.1")}
        </Typography>
      </Grid>
      <Grid item sm={12} marginTop={2}>
        <form onSubmit={handleSubmit} onChange={handleChange}>
          <Grid container spacing={2}>
            <Grid item sm={12} md={4}>
              <TextField {...fields.dateFrom} />
            </Grid>
            <Grid item sm={12} md={4}>
              <TextField {...fields.dateTo} />
            </Grid>
            <Grid item xs={12}>
              <Typography type="p" variant="body1">
                {t("config_assistant.fiscal_year.2")}
              </Typography>
            </Grid>
            <Grid item sm={12}>
              <Select
                {...fields.weekFirstDay}
                onChange={handleChangeDay}
              ></Select>
            </Grid>
            {/* <Grid item sm={12} md={4}> */}
            {/*   <TextField {...fields.status} onChange={handleSelect} /> */}
            {/* </Grid> */}
            <Grid item sm={12} marginTop={2}>
              <Grid
                container
                spacing={2}
                display={"flex"}
                justifyContent={"space-between"}
              >
                <Grid item sm={12} md={4}>
                  <LoadingButton
                    loading={loading}
                    variant="contained"
                    component="label"
                    color="primary"
                    onClick={handleSubmit}
                  >
                    {t("save")}
                  </LoadingButton>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={4}
                  display={"flex"}
                  justifyContent={"right"}
                >
                  <Button
                    onClick={handleCancel}
                    variant="contained"
                    component="label"
                    color="secondary"
                  >
                    {t("cancel")}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </>
  );
}
